
import { Component, Prop, Vue } from 'vue-property-decorator'
import newIcon from '~/components/shared/icon/NewIcon.vue'
import MyBookshelf from '~/components/shared/MyBookshelf.vue'
import categoryIcon from '~/components/shared/icon/ArticleCategoryType.vue'

@Component({
  components: {
    newIcon,
    categoryIcon,
    MyBookshelf
  }
})
export default class CardHeader extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  private articleId!: number

  @Prop({
    type: String,
    required: true
  })
  private articleLink!: string

  @Prop({
    type: String,
    required: false
  })
  private openDate!: string

  @Prop({
    type: String,
    required: false
  })
  private used!: string

  @Prop({
    type: Boolean,
    required: false
  })
  private bookshelf!: boolean

  @Prop({
    type: String,
    required: false
  })
  private articleType!: string
}
