
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardHeader from '~/components/shared/card/CardHeader.vue'
import categoryIcon from '~/components/shared/icon/ArticleCategoryType.vue'
import { Stories } from '~/types/stories'

@Component({
  components: {
    CardHeader,
    categoryIcon
  }
})
export default class ArticleCard extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private article!: Stories.StoryRecords

  // categoriesからの遷移時にパラメータ付与する
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private paramSrag: string

  @Prop({
    type: Boolean,
    required: false
  })
  private bigCard: boolean

  // cardの利用箇所によってstyle分岐させる
  // TODO: 他のstyleもこの管理にしたい
  @Prop({
    type: String,
    required: false
  })
  private used: string

  private get articleLink() {
    return this.paramSrag
      ? this.article.path + `?category=${this.paramSrag}`
      : this.article.path
  }

  private get isSavedBookshelf() {
    return this.used === 'bookshelf' ? true : this.article.isSaved
  }
}
